<template>
  <div v-if="files" class="files-content">
    <div v-if="files.length" class="file-container">
      <draggable
        :list="files"
        :force-fallback="true"
        class="section-files"
        @change="updateOrderFiles"
      >
        <div
          v-for="(file, index) in files"
          :key="index"
          class="file"
          :class="index % 2 === 0 ? 'even' : 'odd'"
        >
          <Icons name="20px/drag_purple" class="drag-icon" />
          <div class="section-image">
            <Icons
              name="16px/close_filled"
              @click="$emit('detachFiles', index)"
            />
            <img
              v-if="file.encode_file && isImage(file)"
              :src="file.encode_file"
              class="image"
            />
            <img
              v-else-if="file.url && isImage(file)"
              :src="file.url"
              class="image"
            />
            <video
              class="image"
              muted
              preload="auto"
              loop
              controls
              v-if="file.encode_file && isVideo(file)"
              :src="file.encode_file"
            />
            <video
              class="image"
              muted
              preload="auto"
              loop
              controls
              v-else-if="file.url && isVideo(file)"
              :src="file.url"
            />
            <div class="not-image-container" v-if="isPDF(file)">
              <a :href="file.url" target="_blank"
                ><Icons name="Backoffice/file_text" class="icon-image"
              /></a>
            </div>
          </div>
        </div>
      </draggable>
      <div class="add-file" v-if="files.length">
        <el-upload
          v-model:file-list="fileList"
          multiple
          :show-file-list="false"
          :http-request="() => {}"
          :on-change="handleSuccess"
          :on-remove="handleRemove"
        >
          <ElButton type="link">
            {{ $t('add') }}
          </ElButton>
        </el-upload>
      </div>
    </div>
    <div v-else class="section-files-not-found">
      <el-upload
        v-model:file-list="fileList"
        multiple
        drag
        :show-file-list="false"
        :http-request="() => {}"
        class="gallery-uploader"
        :on-change="handleSuccess"
        :on-remove="handleRemove"
      >
        <ElButton type="link">
          {{ $t('click_or_drop_image') }}
        </ElButton>
      </el-upload>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent({
  props: {
    files: {
      type: Array,
      default: () => []
    }
  },
  components: {
    draggable: VueDraggableNext
  },
  setup() {
    const { t } = useI18n()
    const { $showError } = useNuxtApp()
    const { fileSizeLimit } = getFormInitialVariables()

    return {
      t,
      $showError,
      fileSizeLimit
    }
  },
  data() {
    return {
      fileList: [],
      filesToUpload: []
    }
  },
  methods: {
    handleSuccess(response, uploadFile, uploadFiles) {
      if (response?.raw) {
        if (
          ![
            'image/jpeg',
            'image/png',
            'image/gif',
            'image/webp',
            'image/svg+xml',
            'video/mp4',
            'video/3gpp',
            'video/webm',
            'application/pdf'
          ].includes(response.raw.type)
        ) {
          this.$showError(t('invalid_image'))
        } else if (response.raw.size > this.fileSizeLimit) {
          this.$showError(
            t('file_size_limit', { size: this.fileSizeLimit / 1000000 })
          )
        } else {
          this.filesToUpload.push(response.raw)
          this.submit()
        }
      }
    },
    handleRemove(uploadFile, uploadFiles) {
      if (uploadFile) {
        this.filesToUpload = this.filesToUpload.filter(
          (file) => file.uid !== uploadFile.uid
        )
        this.submit()
      }
    },
    submit() {
      if (this.filesToUpload.length > 0) {
        this.$emit('uploadImages', this.filesToUpload)
        this.fileList = []
        this.filesToUpload = []
      }
    },
    getCreatedAtFormat(date) {
      return date
        ? `${moment(date).format('DD/MM/YYYY')} | ${moment(date).format(
            'HH:mm'
          )}`
        : ''
    },
    isImage(file) {
      return file.file_mime_type?.includes('image/') ?? false
    },
    isVideo(file) {
      return file.file_mime_type?.includes('video/') ?? false
    },
    isPDF(file) {
      return file?.file_mime_type?.includes('pdf')
    },
    updateOrderFiles(event) {
      this.$emit('updateOrderFiles', {
        uuid: event?.moved?.element?.uuid,
        order: event?.moved?.newIndex + 1
      })
    }
  }
})
</script>

<style scoped lang="scss">
.not-image-container {
  a {
    i.icon-image {
      position: absolute !important;
      left: 17px !important;
      top: 19px !important;
    }
  }
}
.files-content {
  @media (max-width: 1100px) {
    margin-top: 32px;
  }
  width: 100%;
  .file-title {
    height: 40px;
    margin-bottom: 16px;
  }
  .file-container {
    background-color: var(--brand-off-white);
    padding: 8px 8px 8px 0;
    .section-files {
      display: flex;
      flex-wrap: wrap;
      .file {
        cursor: pointer;
        border-radius: 4px;
        background-color: var(--brand-white);
        width: calc(64px + 24px);
        height: 64px;
        .drag-icon {
          position: relative;
          top: 20px;
          left: 6px;
        }
        .section-image {
          position: relative;
          left: 24px;
          top: -20px;
          width: 64px;
          height: 64px;

          i {
            position: absolute;
            top: 2px;
            right: 2px;
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
          .image {
            border-radius: 4px;
            width: 64px;
            height: 64px;
          }
        }
        margin-left: 8px;
        margin-bottom: 8px;
      }
    }
    .add-file {
      margin-top: 16px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
